import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";

import AdminLayout from "layouts/Admin.jsx";
import config from "./config";
import Amplify, { Auth } from "aws-amplify";
import Unauthorized from "./controllers/Unauthorized";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.REGION,
    userPoolId: config.COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.COGNITO_APP_CLIENT_ID,
    oauth: {
      domain: config.COGNITO_DOMAIN,
      scope: ["email", "openid"],
      redirectSignIn: config.COGNITO_REDIRECT_SIGN_IN,
      redirectSignOut: config.COGNITO_REDIRECT_SIGN_OUT,
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: "admin",
        endpoint: config.API_URL,
        region: config.REGION,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
            "x-api-key": config.API_KEY,
          };
        },
      },
    ],
  },
});

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route
        path="/unauthorized"
        render={(props) => <Unauthorized {...props} />}
      />
      <Redirect from="/" to="/admin/exercises" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
