import { buildSelector, constToSelectOptions } from "../utils/Utils";

export const ExerciseKeys = ["body_part", "equipment", "exercise_level"];

export const ExerciseModel = (constants) => {
  return {
    id: {
      value: null,
      hidden: true,
    },
    name: {
      type: "input",
      validationRules: "required",
      value: "",
    },
    preview: {
      type: "input",
      validationRules: ["required", { regex: "^https://mobile.appscdn.io/.*" }],
      value: "",
    },
    symmetry: buildSelector(
      constToSelectOptions(constants, "symmetry"),
      "select"
    ),
    media_url: {
      type: "input",
      validationRules: ["required", { regex: "^https://mobile.appscdn.io/.*" }],
      value: "",
    },

    body_parts: buildSelector(
      constToSelectOptions(constants, "body_part"),
      "multiSelect"
    ),
    equipment: buildSelector(
      constToSelectOptions(constants, "equipment"),
      "multiSelect"
    ),
    exercise_level: buildSelector(
      constToSelectOptions(constants, "exercise_level"),
      "select"
    ),

    base_pace: {
      inputType: "number",
      validationRules: "required",
      value: "",
      requestNormalizer: (data) => {
        return parseFloat(data);
      },
    },
    base_mets: {
      inputType: "number",
      validationRules: "required",
      value: "",
      requestNormalizer: (data) => {
        return parseFloat(data);
      },
    },
    one_rm: {
      inputType: "number",
      validationRules: "required",
      value: "",
    },
    tags: buildSelector(constToSelectOptions(constants, "tags"), "multiSelect"),
  };
};

export const ExerciseFilter = (constants) => {
  let model = {};

  for (const field_name of ExerciseKeys) {
    model[field_name] = buildSelector(
      constToSelectOptions(constants, field_name)
    );
  }
  return {
    name: {
      type: "input",
      value: "",
    },
    ...model,
    tags: buildSelector(
      constToSelectOptions(constants, "tags"),
      "multiSelect",
      (data) => {
        return JSON.stringify(data.map((a) => a.value));
      }
    ),
  };
};
