export default {
  app: {
    name: "Fasting",
  },
  userGroups: {
    admin: "admin",
    viewer: "viewer",
    editor: "editor",
    support: "support",
  },
};
